<template>
    <div class="table-responsive table-striped my-1">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Status</th>
            <th>Role</th>
            <th>Modules</th>
            <th>Action</th>
          </tr>
          </tbody>
            <tbody>
                <template v-if="users && users.length">
                    <tr v-for="user in users" :key="user.id">
                        <td><img width="100" :src="user.photo" alt=""></td>
                        <td>{{ user.user_name }}</td>
                        <td>{{ user.status }}</td>
                        <td>
                          <span v-for="company_user_role in user.company_user_roles" class="badge bg-primary mx-25">{{ company_user_role.label }}</span>
                        </td>
                        <td>
                          <span v-for="module in user.company?.modules" class="badge bg-primary mx-25">{{ module.name }}</span>
                        </td>
                        <td>
                            <slot :user="user"></slot>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>

export default {
    name: 'UserListTable',
    props: ['users']
}
</script>
